import '../scss/main.scss';
import 'bootstrap';

(function() {
  let intervalId;

  function moveReviews() {
    let reviewCarouselItems = document.querySelector('.review-carousel-items');
    let lastReviewCard = reviewCarouselItems.lastElementChild;
    reviewCarouselItems.insertBefore(lastReviewCard, reviewCarouselItems.firstElementChild);
  }

  function startCarousel() {
    intervalId = setInterval(moveReviews, 8000);
  }

  function stopCarousel() {
    clearInterval(intervalId);
  }

  // Start the carousel if .review-carousel exists
  if ($('.review-carousel').length > 0) {
    startCarousel();

    // Use .on() to add hover events to dynamically handle pause/resume
    $('.review-carousel-items').on('mouseenter', '.review-card', function() {
      stopCarousel(); // Pause on hover
    });

    $('.review-carousel-items').on('mouseleave', '.review-card', function() {
      startCarousel(); // Resume on mouse leave
    });
  }
})();

(function() {
  const contactForm = document.querySelector("#contact-form");
  const contactFormSubmitButton = contactForm.querySelector("[data-action-button=\"submit\"]");

  const submitForm = () => {
    contactFormSubmitButton.innerHTML = `SUBMITTING...`;

    const formData = new FormData(contactForm);

    const jsonData = {};
    formData.forEach((value, key) => {
      jsonData[key] = value;
    });

    fetch('/api/contact-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jsonData)
    })
    .then(response => {
      if (response.ok)
        return response.json();
      throw response
    })
    .then(data => {
      console.log("Success");
      contactFormSubmitButton.innerHTML = `SEND MESSAGE`;
    })
    .catch(error => {
      if (error.json) {
        error.json().then(data => {
          console.error("Failed with server error: ", data);
        }).catch(() => {
          console.error("Failed to parse error response.");
        });
      } else {
        console.log("Network or unknown error: ", error);
      }
      contactFormSubmitButton.innerHTML = `SEND MESSAGE`;
    });
  };

  contactFormSubmitButton.addEventListener('click', () => {
    submitForm();
  });
})();